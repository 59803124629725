<template>
  <div class="accordion-history">
    <history-item :history="dataReverse" />
  </div>
</template>

<script>
export default {
  name: 'accordion-history',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    HistoryItem: () => import('./HistoryItem.vue'),
  },
  computed: {
    isUnique() {
      return this.data.length === 1;
    },
    dataReverse() {
      /* eslint-disable */
      const history = Object.keys(this.data).map(item => {
        return {
          year: item,
          months: Object.keys(this.data[item]).map(month => {
            return {
              month: `${month}`,
              days: Object.keys(this.data[item][month]).map(day => {
                return {
                  day: `${day}`,
                  items: this.data[item][month][day],
                };
              }).sort(function(a, b){return parseInt(a['day']) - parseInt(b['day'])}),
            };
          }).sort(function(a, b){return parseInt(a['month']) - parseInt(b['month'])}),
        };
      });
      /* eslint-enable */
      return history.reverse();
    },
  },
  methods: {
    isLast(index) {
      return index === this.data.length - 1;
    },
  },
};
</script>

<style lang="scss"></style>
